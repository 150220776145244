<template>
  <div class="specialtyTree">
    <div v-for="item in specialtyTree" :key="item.categoryId" class="fordiv">
      <div class="category">
        <div class="titleleft">
          <div class="img"></div>
          {{ item.categoryName }}
        </div>
        <div class="line"></div>
      </div>
      <div
        v-for="discipline in item.disciplineList"
        :key="discipline.disciplineId"
        class="discipline"
      >
        <div class="disciplinetitle">
          <div class="round"></div>
          <div class="smalltitle">
            {{ discipline.disciplineName }}
          </div>
        </div>
        <div class="disciplineright">
          <div
            v-for="specialty in discipline.specialtyList"
            :key="specialty.specialtyId"
            class="specialty"
            @click="gospecialtydetail(specialty.specialtyId)"
          >
            &nbsp;&nbsp;{{ specialty.specialtyName }} |
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSpecialtyTree } from "@/api/dataquery";
export default {
  data() {
    return {
      specialtyTree: [], //专业树
    };
  },
  mounted() {
    this.getSpecialtyTree();
  },
  methods: {
    //获取专业树
    getSpecialtyTree() {
      getSpecialtyTree({}).then((res) => {
        if (res.code == 1) {
          this.specialtyTree = res.data || [];
          console.log(this.specialtyTree, "this.specialtyTree");
        }
      });
    },
    // 去专业详情
    gospecialtydetail(specialtyId) {
      this.$router.push({
        path: "/dataQuery/findProfessional/specialtyDetail",
        query: { specialtyId: specialtyId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.specialtyTree {
  width: 100%;
  box-sizing: border-box;
  .fordiv {
    margin-top: 10px;
    width: 100%;
    .category {
      width: 100%;
      display: flex;
      height: 18px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
      .titleleft {
        padding-right: 2px;
        .img {
          display: inline-block;
          width: 10px;
          height: 14px;
          background-image: url("../../../assets/find/矩形36.png");
          margin-right: 2px;
          margin-top: 2px;
        }
      }
      .line {
        margin-top: 8px;
        flex: auto;
        border-top: 1px solid #e6e6e6;
        overflow: hidden;
      }
    }
    .discipline {
      box-sizing: border-box;
      display: flex;
      padding-left: 60px;
      min-height: 30px;
      width: 100%;
      margin-bottom: 10px;
      .disciplinetitle {
        display: flex;
        box-sizing: border-box;
        width: 120px;
        height: 100%;
        .round {
          box-sizing: border-box;
          width: 6px;
          height: 6px;
          background: #1ab394;
          border-radius: 3px;
          margin-top: 12px;
        }
        .smalltitle {
          flex: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 30px;
          color: #333333;
          padding-left: 6px;
        }
      }
      .disciplineright {
        flex: auto;
        box-sizing: border-box;
        display: inline-block;
        width: 730px;
        .specialty {
          cursor: pointer;
          box-sizing: border-box;
          display: inline-block;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
